import React, { useEffect } from 'react';

const isClient = () => {
  return typeof window === 'object';
}

const Discord: React.FC = () => {
  useEffect(() => {
    if (isClient()) {
      window.location.href = 'https://discord.gg/P4Z4ua6sRS';
    }
  }, []);

  return <div />;
}

export default Discord;